import React, { Component } from "react";
import "./App.css";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

export default class App extends Component {
  state = {
    amount: 0,
    currency: "GBP",
  };

  componentDidMount = () => {
    this.setState({
      amount: localStorage.getItem("amount"),
      currency: localStorage.getItem("currency"),
    });
    // console.log("Hi mod");
  };

  _createOrder(data, actions) {
    // alert("Hi mod");
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: this.state.amount,
          },
        },
      ],
    });
  }
  async _onApprove(data, actions) {
    let order = await actions.order.capture();
    console.log(order);
    window.ReactNativeWebView &&
      window.ReactNativeWebView.postMessage(JSON.stringify(order));
    return order;
  }
  _onError(err) {
    console.log(err);
    let errObj = {
      err: err,
      status: "FAILED",
    };
    window.ReactNativeWebView &&
      window.ReactNativeWebView.postMessage(JSON.stringify(errObj));
  }

  render() {
    return (
      <div className="App">
        <PayPalScriptProvider
          options={{ "client-id": "AYhLh_HMRxtwWSa7vKVorAGNB6ed8yBrUW42qfLaY48nMhgQgX_IdsCCBeIraR6kLogkbyESH-cGcGxF", currency: this.state.currency }}
        >
          <PayPalButtons
            createOrder={(data, actions) => this._createOrder(data, actions)}
            onApprove={(data, actions) => this._onApprove(data, actions)}
            onCancel={() => this._onError("Canceled")}
            onError={(err) => this._onError(err)}
          />
        </PayPalScriptProvider>
      </div>
    );
  }
}
